/* Defines layout */

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.center-self-v {
  margin: 0 auto;
}

.center-self-h {
  margin: auto 0;
}

.center-self-vh {
  margin: auto;
}

.center-child-vh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.center-text {
  text-align: center;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-42 {
  margin-bottom: 42px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-56 {
  margin-bottom: 56px;
}

.pl-8 {
  padding-left: 8px;
}

.full-width {
  width: 100%;
}

.level {
  position: relative;
  border-bottom: 1px solid #dfe0e1;
  background: #fff;
  background: -webkit-linear-gradient(-180deg, #fff, #fff, #eee);
  background: -o-linear-gradient(-180deg, #fff, #fff, #eee);
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(#fff), to(#eee));
  background: -webkit-linear-gradient(top, #fff, #fff, #eee);
  background: -o-linear-gradient(top, #fff, #fff, #eee);
  background: linear-gradient(-180deg, #fff, #fff, #eee);
  overflow: hidden;
  padding: 50px 0 30px;
  text-align: center;
}

.visible-xs {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.hidden-xs {
  display: none !important;
}

@media (min-width: 375px) {
  .visible-375 {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .hidden-375 {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .visible-sm {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .hidden-sm {
    display: none !important;
  }

  .level {
    padding: 140px 0 160px;
    padding: 6.364rem 0 7.273rem;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .visible-md {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .visible-lg {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .hidden-lg {
    display: none !important;
  }
}

nav {
  /* height: 260px; */
  display: none;
  position: relative;
  background-color: #fff;
}

/* Need some more work here */
/* @media (min-width: 1200px) {
.level div.container {
    width: 1170px;
}
}
@media (min-width: 992px) {
.level div.container {
    width: 970px;
}}
@media (min-width: 768px) {
.level div.container {
    width: 750px;
}
}
.level div.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} */
